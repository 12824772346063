.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

@media (max-width: 426px) {
  .content {
    padding: 0 9px;
  }
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #0074c6;
  padding: 0 10%;
  z-index: 5;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #2a495e;
  margin-top: 32px;
  margin-bottom: 28px;
  text-align: center;
  margin-top: 80px;
}

@media (max-width: 426px) {
  .title {
    font-size: 21px;
    margin: 80px 0 18px 0;
  }
}

.uploader {
  position: relative;
  max-width: 800px;
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f2f5fa;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 10px;
}

.uploader__button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #0074c6;
  border-radius: 100px;
  min-width: 220px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  margin: 15px 0;
  z-index: 2;
  transition: background-color 0.2s ease;
}

.uploader__button:hover {
  background-color: #0066af;
}

.uploader__button_dragging {
  z-index: 0;
}

.uploader__dnd {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px dashed rgba(42, 73, 94, 0.22);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
}

.uploader__dnd_dragging {
  border-color: #0074c6;
}

.uploader__rules {
  display: flex;
  flex-direction: column;
}

.uploader__rules span {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 23px;
  color: rgba(42, 73, 94, 0.5);
  white-space: nowrap;
}

.uploader__rules span:not(:last-child) {
  margin-bottom: 12px;
}

.uploader__rules__prefix {
  background: rgba(42, 73, 94, 0.22);
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 16px;
}

.font__blue {
  color: #0074c6 !important;
}

.font__gray {
  color: rgba(42, 73, 94, 0.22) !important;
}

.placeholder {
  max-width: 434px;
  width: 100%;
  margin-top: 58px;
  transition-property: height, opacity, margin-top;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.placeholder_hidden {
  opacity: 0;
  height: 0;
  margin-top: 0;
}

.files-list {
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  max-width: 1100px;
  width: 100%;
  margin: 24px 0;
  height: calc(100vh - 358px);
  transition-property: height, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 0.5s;
  overflow: hidden;
}

.files-list > div {
  overflow: auto;
}

.files-list_empty {
  height: 0 !important;
  opacity: 0;
  transition: none;
}

.files-list__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(100% - 56px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edf0f5;
  padding: 0 24px;
}

.files-list__header > span {
  font-size: 16px;
  line-height: 23px;
  color: #2a495e;
  white-space: nowrap;
  margin-right: 12px;
}

.files-list__items {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.files-list__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0 24px;
  transition-property: height, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  animation: fade-in 0.7s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.files-list__item:hover {
  background: #f5f8fb;
}

@media (max-width: 426px) {
  .files-list__item {
    padding: 0 12px;
  }
}

.files-list__item_hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.files-list__item__name {
  display: flex;
  align-items: center;
  flex: 6;
  min-width: 50px;
  height: 100%;
}

.files-list__item__name__img {
  margin-right: 36px;
}

.circle-animation {
  animation: circle 1s linear infinite;
}

@keyframes circle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 426px) {
  .files-list__item__name__img {
    margin-right: 12px;
    width: 20px;
  }
}

.files-list__item__name > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #0074c6;
  max-width: 80%;
  width: 100%;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.files-list__item__direction {
  font-size: 14px;
  line-height: 20px;
  color: rgba(42, 73, 94, 0.5);
  text-transform: uppercase;
  flex: 1;
}

.files-list__item__status {
  font-size: 14px;
  line-height: 16px;
  color: rgba(42, 73, 94, 0.5);
  text-align: center;
  flex: 2;
}

.files-list__item__control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.files-list__item__control button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.files-list__item__control button:first-child {
  margin-right: 12px;
}

.files-list__search {
  position: relative;
  display: flex;
  max-width: 174px;
}

.files-list__search img {
  position: absolute;
  top: 5px;
  left: 9px;
}

.files-list__search input {
  background: #edf0f5;
  color: #2a495e;
  border-radius: 100px;
  outline: none;
  border: none;
  height: 34px;
  width: 100%;
  padding-left: 36px;
}

.files-list__search:hover input,
.files-list__search input:focus {
  background: #dbdfe49e;
}

.notifications {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  bottom: 12px;
  right: 40px;
  z-index: 7;
  transition: height 0.5s ease;
}

.notification {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);
  border-radius: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  max-width: 500px;
  width: fit-content;
  min-width: 300px;
  max-height: 400px;
  overflow: auto;
  flex-shrink: 0;
  animation: slide 0.5s ease;
}

@keyframes slide {
  from {
    transform: translateX(600px);
  }

  to {
    transform: translateX(0);
  }
}

.notification__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 21px 12px 21px;
}

.notification__header > div {
  display: flex;
}

.notification__header > div > img {
  margin-right: 12px;
}

.notification__header span {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.notification__header > button {
  margin-left: 21px;
}

.notification__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  padding: 0 21px;
}

.notification__content > span {
  font-size: 14px;
  line-height: 23px;
  color: #2a495e;
  word-break: break-word;
}

.notification__content > span:not(:last-child) {
  margin-bottom: 12px;
}

.notification__timer {
  position: absolute;
  height: 9px;
  opacity: 0.6;
  top: 0;
}

@keyframes timer {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.close-btn {
  height: 32px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.file-table__wrapper {
  padding: 0 12px 4px 12px;
  background-color: #f3f3f3;
}

.file-about-table {
  display: grid;
  grid-template-columns: 100px calc(100% - 100px);
  grid-auto-rows: 40px;
}

.file-about-table__cell {
  display: flex;
  align-items: center;
}

.file-about-table__cell:not(:nth-last-child(-n + 2)) {
  border-bottom: 1px solid #e0e0e0;
}

.file-errors-table {
  display: grid;
  grid-template-columns: 40px 20% 10% 15% 1fr 10%;
  grid-auto-rows: minmax(40px, 100%);
}

.file-errors-table__cell:not(:nth-last-child(-n + 6)) {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.file-errors-table > div:not(:nth-child(6n)) {
  padding-right: 21px;
}

.file-errors-table__cell {
  display: flex;
  align-items: center;
}

.file-errors-table__cell_empty {
  justify-content: center;
  font-weight: bold;
}

.file-errors-table__cell > span {
  overflow-wrap: anywhere;
}

.files-viewer {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.22);
  border-radius: 6px 0 0 6px;
  padding: 24px;
  max-width: 90%;
  width: 100%;
  min-width: 300px;
  transform: translateX(100%);
  opacity: 0;
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  overflow: auto;
  z-index: 6;
}

.files-viewer_active {
  transform: translateX(0);
  opacity: 1;
}

.files-viewer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.files-viewer__header__title {
  justify-content: space-between;
  width: 100%;
}

.files-viewer__header__title,
.files-viewer__header__title_name-wrapper {
  display: flex;
  align-items: center;
}

.files-viewer__header__title_name-wrapper {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0f1b4c;
}

.files-viewer__header__title__name {
  word-break: break-all;
}

.files-viewer__header__title__status {
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin: 0 9px;
  padding: 4px 6px;
}

.files-viewer__header__title > span > img {
  margin-right: 12px;
}

.files-viewer__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  white-space: break-spaces;
  font-size: 14px;
  line-height: 17px;
  color: #0f1b4c;
}

.files-viewer__text {
  overflow: auto;
  background: #f1f8ff;
  padding: 12px;
  margin: 12px 0;
  flex-grow: 1;
}

.files-viewer__asserts {
  display: flex;
  flex-direction: column;
  color: #3c1616;
  background: rgb(255 240 240);
  padding: 12px;
  line-height: 18px;
  overflow: auto;
  max-height: 40%;
  flex-shrink: 0;
}

.files-viewer__asserts-item:not(:last-child) {
  margin-bottom: 12px;
}

.files-list__control_delete:hover > svg > path {
  fill: red;
  fill-opacity: 0.6;
}

.files-list__control_view:hover > svg > path {
  fill: blue;
  fill-opacity: 0.6;
}

.files-list__control_shake:hover {
  animation: rotation 0.3s linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-15deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0);
  }
}

.tooltip {
  position: absolute;
  padding: 9px 16px;
  background: #fff;
  top: 70%;
  left: 80px;
  max-width: 40%;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 1;
  word-break: break-all;
  animation: tooltip 0.1s linear;
}

.tooltip_last {
  top: -70%;
}

@keyframes tooltip {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .files-list {
    height: 100%;
    min-height: 230px;
  }
}

@media (max-width: 626px) {
  .notifications {
    bottom: 6px;
    left: 1%;
    right: 1%;
    max-width: 100%;
    width: 98%;
  }
  .notification {
    width: 100%;
  }
  .files-list {
    height: calc(100vh - 444px);
  }
}

@media (max-width: 426px) {
  .uploader__button {
    min-width: 200px;
    height: 36px;
    font-size: 14px;
  }
  .uploader {
    padding: 21px 0 4px 0;
  }
  .uploader__rules > span {
    font-size: 14px;
    line-height: 16px;
  }
  .uploader__rules > span > div {
    width: 6px;
    height: 6px;
    margin-right: 6px;
  }
  .notification__content > span {
    font-size: 14px;
  }
  .notification__header span {
    font-size: 18px;
  }
  .files-list {
    height: calc(100vh - 394px);
  }
}
